:root[data-theme='dark'] {
  --background: #282c34;
  --background2: #251101;
  --background3: #2c5784;
  --primary: #c1cfda;
  --secondary: #282c34;
  --alternate: #20a4f3;
  --twitter: #2bc4ff;
  --facebook: #4c6ebe;
  --linkedin: #008fdd;
  --git: #f5f5f5;
  --whatsapp: #dcf8c6;
  --youtube: #c4302b;
  --mail: #ffce44;

  //images
  --backimage: url(../Images/darkback.jpg);
}

:root {
  --background: #fcf7f8;
  --background2: #d7f9f1;
  --background3: #fff;
  --primary: #282c34;
  --secondary: #fff;
  --alternate: #5aadd3;
  --twitter: #1ea1f3;
  --facebook: #3b5998;
  --linkedin: #0e76a8;
  --git: #333;
  --whatsapp: #128c7e;
  --youtube: #c4302b;
  --mail: #1dd0ff;
  --backimage: url(../Images/lightback.jpg);
}
