@import "../../Assets/styles/_base";

.projectsection {
  .projects {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    width: 80vw;
    margin: 0 auto;

    .project {
      // background: v(secondary);
      text-decoration: none;
      background: v(backimage);
      background-repeat: no-repeat;
      color: v(primary);
      min-height: 350px;
      border-radius: 10px;
      display: flex;
      // justify-content: flex-end;
      justify-content: center;
      // overflow: hidden;
      position: relative;
      flex-direction: column;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      cursor: url("../../Assets/Images/hand.png"), auto;

      &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
          0 10px 10px rgba(0, 0, 0, 0.22);
      }
      .tag {
        position: absolute;
        top: 10px;
        right: -10px;
        padding: 5px 20px;
        background: yellow;
        font-size: rfs(5);
        text-transform: lowercase;
        color: #333;
        box-shadow: -2px 4px 4px rgba($color: #000000, $alpha: 0.3);

        &:after {
          content: "";
          position: absolute;
          right: -10px;
          bottom: -10px;
          border: 10px solid transparent;
          border-left-color: #555;
          z-index: -1;
          // transform: rotate(45deg);
        }
      }

      .details {
        // background: v(secondary);
        padding: 20px;
        z-index: 1;

        div {
          font-size: rfs(15);
          font-weight: bold;
          text-transform: capitalize;
        }
        p {
          font-size: rfs(8);
        }
      }
    }
  }
}
