@import "breakpoints";

@function v($var) {
  @return var(--#{$var});
}

@function rfs($var) {
  @return calc(#{$var}px + 2vmin);
}

.App {
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(300px);
  max-height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}
.link {
  color: v(secondary);
  z-index: 1;
  text-decoration: none;
  transition: color 0.25s ease-in-out;
  position: relative;
  cursor: url("../Images/hand.png"), auto;

  &:after {
    content: "";
    position: absolute;
    border: none;
    width: 100%;
    height: 2px;
    z-index: -1;
    bottom: 0;
    left: 0;
    background: v(secondary);
    transition: height 0.25s ease-in-out;
  }
  &:hover {
    color: v(alternate);
    &:after {
      height: 100%;
    }
  }
}

.section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: rfs(10);
  color: v(primary);
  transition: background-color 0.35s ease-in-out;
  scroll-snap-align: start;
  scroll-snap-stop: always;

  @include mobile {
    scroll-snap-align: end start;
    scroll-snap-stop: normal;
  }
}
